import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-pop-up-output',
  templateUrl: './pop-up-output.component.html',
  styleUrls: ['./pop-up-output.component.scss']
})
export class PopUpOutputComponent implements OnInit {

  constructor(private activeModal: NgbActiveModal, private router: Router) { }

  ngOnInit(): void {
  }

  close() {
    this.activeModal.close();
  }

  redirectSignUp() {
    this.close();
    const returnIpanema = JSON.parse(sessionStorage.getItem('returnIpanema'));
    if (returnIpanema) {
      this.router.navigate(['painel']);
    } else {
      this.router.navigate(['cadastrar']);
    }
  }

}
