import { Component, HostListener, OnInit } from '@angular/core';
import { ToastService } from '../../services/toast.service';
import { ApoloService } from 'src/app/services/apolo.service';

@Component({
  selector: 'app-public-app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class PublicAppComponent implements OnInit {

  constructor(
    public toastService: ToastService,
    private apoloService: ApoloService
  ) { }

  ngOnInit(): void {
    this.apoloService.sendData();
  }

}
