import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { AbstractService } from './abstract.service';

@Injectable({
  providedIn: 'root',
})
export class PrivacyService extends AbstractService {
  model = 'privacy';

  save(data): any {
    return this.http.put<any>(`${environment.apiUrl}/${this.model}/save`, data);
  }
}
