<div class="card-wrapper {{idGtag}}" (mouseenter)="hover()" (mouseleave)="hover()" (keyDown)="hover()"
  (click)="goTo(target)" tabindex="0" [id]="idGtag" attr.data-evt-collect="{{ fibbia_evt_collector }}"
  attr.data-evt-category="{{ fibbia_evt_category }}" attr.data-evt-type="{{ fibbia_evt_type }}"
  attr.data-evt-context-id="{{ fibbia_evt_context_id }}" attr.data-evt-element-id="{{ fibbia_evt_element_id }}">
  <div class="icon" (click)="clickInnerElements($event)" attr.data-evt-collect="{{ fibbia_evt_collector }}"
    attr.data-evt-category="{{ fibbia_evt_category }}" attr.data-evt-type="{{ fibbia_evt_type }}"
    attr.data-evt-context-id="{{ fibbia_evt_context_id }}" attr.data-evt-element-id="{{ fibbia_evt_element_id }}">
    <img alt="image" (click)="clickInnerElements($event)" attr.data-evt-collect="{{ fibbia_evt_collector }}"
      attr.data-evt-category="{{ fibbia_evt_category }}" attr.data-evt-type="{{ fibbia_evt_type }}"
      attr.data-evt-context-id="{{ fibbia_evt_context_id }}" attr.data-evt-element-id="{{ fibbia_evt_element_id }}"
      [src]="!isHover ? srcIcon : srcIconWhite" rel="preload" />
  </div>
  <h3 class="channel-title" (click)="clickInnerElements($event)" attr.data-evt-collect="{{ fibbia_evt_collector }}"
    attr.data-evt-category="{{ fibbia_evt_category }}" attr.data-evt-type="{{ fibbia_evt_type }}"
    attr.data-evt-context-id="{{ fibbia_evt_context_id }}" attr.data-evt-element-id="{{ fibbia_evt_element_id }}">
    {{ channelTitle | translate }}
  </h3>
  <h4 class="channel-subtitle" (click)="clickInnerElements($event)" attr.data-evt-collect="{{ fibbia_evt_collector }}"
    attr.data-evt-category="{{ fibbia_evt_category }}" attr.data-evt-type="{{ fibbia_evt_type }}"
    attr.data-evt-context-id="{{ fibbia_evt_context_id }}" attr.data-evt-element-id="{{ fibbia_evt_element_id }}"
    [innerHTML]="channelSubtitle | translate"></h4>
  <div class="link-wrapper" (click)="clickInnerElements($event)" attr.data-evt-collect="{{ fibbia_evt_collector }}"
    attr.data-evt-category="{{ fibbia_evt_category }}" attr.data-evt-type="{{ fibbia_evt_type }}"
    attr.data-evt-context-id="{{ fibbia_evt_context_id }}" attr.data-evt-element-id="{{ fibbia_evt_element_id }}">
    <span class="link" (click)="clickInnerElements($event)" routerLink="/"
      attr.data-evt-collect="{{ fibbia_evt_collector }}" attr.data-evt-category="{{ fibbia_evt_category }}"
      attr.data-evt-type="{{ fibbia_evt_type }}" attr.data-evt-context-id="{{ fibbia_evt_context_id }}"
      attr.data-evt-element-id="{{ fibbia_evt_element_id }}">
      {{ action | translate }}
      <img class="arrow-up" (click)="clickInnerElements($event)" alt="image" [src]="
          !isHover
            ? 'assets/images/fi_arrow-up-right.svg'
            : 'assets/images/fi_arrow-up-right_white.svg'
        " class="channel-icon" rel="preload" />
    </span>
  </div>
</div>