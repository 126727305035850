<app-loading [show]="loading"></app-loading>

<div class="home-container">
  <div class="home-hero">
    <div class="wrapper-title">
      <div class="cpf-container">
        <form>
          <div class="cpf-wrapper d-flex justify-content-between ">
            <h3 class="cpf-clear-title hide-if-mobile">
              Consulte gratuitamente se há dívidas em seu CPF ou CNPJ e negocie com a gente!
            </h3>
            <br class="hide-if-mobile" />
            <div class="home-cpf-wrapper hide-if-mobile">
            <app-cpf-cnpj-input idGtag="btn_search_home_bottom" class="app-cpf-cnpj-input"
                (click)="cpfcnpjFooterButtonGTM()"></app-cpf-cnpj-input>
              </div>
            <div class="home-cpf-wrapper-mobile  hide-if-desktop">
            <app-cpf-cnpj-input-mobile idGtag="btn_search_home_bottom" class="app-cpf-cnpj-input"
                (click)="cpfcnpjFooterButtonGTM()"></app-cpf-cnpj-input-mobile>
              </div>

          </div>
        </form>
        <!-- SUBSCRIBE - SECTION - QUOD -->
        <!-- <form>
          <div class="subscribe-wrapper d-flex align-center">
            <h3 class="subscribe-clear-title">{{'HOME.SUBSCRIBE_QUOD.TITLE' | translate}}</h3>
            <a target="_blank"
              href="https://cadastro-positivo.vocequod.com.br/?_ga=2.140069747.1565073068.1637248105-1815061848.1637248105"><button
                type="button" class="button primary home-debt-button hide-if-mobile">{{'HOME.SUBSCRIBE_QUOD.BTN-NEXT'
                | translate}}
              </button></a>
            <br class="hide-if-mobile" />
          </div>
        </form> -->
      </div>
      <!-- <a class="image-container" (click)="redirectSignUp()"></a> -->
      <!-- <div class="image-container"></div> -->
      <app-banner-home></app-banner-home>
    </div>
  </div>
  <div class="container-fluid partners">
    <div class="row" style="align-items: baseline;">
      <div class="col-sm-12 col-lg-4 col-md-12 parceiras">
        <h4>Empresas parceiras da Ipanema<br> disponíveis para negociar</h4>
      </div>
      <div class=" col-sm-12 col-lg-8 col-md-12 carousel-logos">
        <button (click)="swipePrev()" class="icon-left"><img style="width: 20px;" src="assets/images/arrowleft.svg"></button>
        <swiper [config]="configCompanies">
          <div class="swiper-wrapper">
            <div class="swiper-slide">
              <div class="card">
                <div class="card__image"><img class="img" src="assets/images/parceiros/Bradesco 2.png" alt="">

                </div>
              </div>
            </div>
            <div class="swiper-slide">
              <div class="card">
                <div class="card__image"><img class="img" src="assets/images/parceiros/Via Varejo 1.png" alt="">
                </div>
              </div>
            </div>
            <div class="swiper-slide">
              <div class="card">
                <div class="card__image"><img class="img" src="assets/images/parceiros/Banco Pan 1.png" alt="">

                </div>
              </div>
            </div>
            <div class="swiper-slide">
              <div class="card">
                <div class="card__image"><img class="img" src="assets/images/parceiros/itau 2.png" alt="">

                </div>
              </div>
            </div>
            <div class="swiper-slide">
              <div class="card">
                <div class="card__image"><img class="img" src="assets/images/parceiros/pernambucanas-logo 1.png" alt="">

                </div>
              </div>
            </div>
            <div class="swiper-slide">
              <div class="card">
                <div class="card__image"><img class="img" src="assets/images/parceiros/Banco BV 2.png" alt="">

                </div>
              </div>
            </div>
            <div class="swiper-slide">
              <div class="card">
                <div class="card__image"><img class="img" src="assets/images/parceiros/Credsystem 2.png" alt="">

                </div>
              </div>
            </div>
            <div class="swiper-slide">
              <div class="card">
                <div class="card__image"><img class="img" src="assets/images/parceiros/credz 2.png" alt="">

                </div>
              </div>
            </div>
            <div class="swiper-slide">
              <div class="card">
                <div class="card__image"><img class="img" src="assets/images/parceiros/Marisa 1.png" alt="">

                </div>
              </div>
            </div>
            <div class="swiper-slide">
              <div class="card">
                <div class="card__image"><img class="img" style=" width: 65% !important; margin-left: 35px !important;" src="assets/images/parceiros/Sky 2.png" alt="">

                </div>
              </div>
            </div>
            <div class="swiper-slide">
              <div class="card">
                <div class="card__image"><img class="img" src="assets/images/parceiros/santander 1.png" alt="">

                </div>
              </div>
            </div>
            <div class="swiper-slide">
              <div class="card">
                <div class="card__image"><img class="img" src="assets/images/parceiros/agibank 2.png" alt="">

                </div>
              </div>
            </div>
            <div class="swiper-slide">
              <div class="card">
                <div class="card__image"><img class="img" src="assets/images/parceiros/algar-telecom-1 1.png" alt="">

                </div>
              </div>
            </div>
            <div class="swiper-slide">
              <div class="card">
                <div class="card__image"><img class="img" style="    width: 70% !important;" src="assets/images/parceiros/Trigg 2.png" alt="">

                </div>
              </div>
            </div>
            <div class="swiper-slide">
              <div class="card">
                <div class="card__image"><img class="img" src="assets/images/parceiros/stone.png" alt="">
                </div>
              </div>
            </div>
            <div class="swiper-slide">
              <div class="card">
                <div class="card__image"><img class="img" src="assets/images/parceiros/btg-pactual 2.png" alt="">
                </div>
              </div>
            </div>
            <div class="swiper-slide">
              <div class="card">
                <div class="card__image"><img class="img" src="assets/images/parceiros/Digio 2.png" alt="">

                </div>
              </div>
            </div>
            <div class="swiper-slide">
              <div class="card">
                <div class="card__image"><img class="img" src="assets/images/parceiros/Tribanco 2.png" alt="">

                </div>
              </div>
            </div>
            <div class="swiper-slide">
              <div class="card">
                <div class="card__image"><img class="img" src="assets/images/parceiros/Magalu 2.png" alt="">

                </div>
              </div>
            </div>
            <div class="swiper-slide">
              <div class="card">
                <div class="card__image"><img class="img" src="assets/images/parceiros/Jequiti 2.png" alt="">

                </div>
              </div>
            </div>

          </div>
        </swiper>

    <button (click)="swipeNext()" class="icon-right"><img style="width: 20px;" src="assets/images/arrowright.svg"></button>

      </div>

    </div>
  </div>

  <div class="channel-section">
    <app-channels-section></app-channels-section>
  </div>
  <!-- <div class="banner-section container align-center">
    <a class="image-banner feirao" (click)="redirectSignUp()">
    </a>
  </div> -->

  <!-- <div class="segments pb-5 pt-5">
    <h5 class="negotiate text-center pb-5">{{ 'HOME.SEGMENTS' | translate }}</h5>
    <app-segment-carousel></app-segment-carousel>
  </div>
 -->
  <!-- Por que negociar -->
  <div class="reason">
    <div class="container">
      <h2>{{'HOME.REASONS.TITLE' | translate}}</h2>
      <p>{{'HOME.REASONS.SUBTITLE' | translate}}</p>
      <div class="reasons-content desktop row hide-if-mobile-swiper">
        <div class="reasons-step" *ngFor="let step of ['1', '2', '3', '4']; let i = index">
          <div class="images-carousel d-flex align-items-center">
            <div class="card-reasons-step">
              <a href="javascript:void(0)" (click)="onClick(step)">
                <img loading="lazy" alt="image-reasons" class="reasons-step-img"
                  [src]="'assets/images/reasons_' + step + '.png'" rel="preload" />
              </a>
              <p class="reasons-step-number mt-2 mb-3">{{ '0' + step }}</p>
              <p class="reasons-step-title">
                {{ 'HOME.REASONS.' + step + '.TITLE' | translate }}
              </p>
              <p class="reasons-step-subtitle">
                {{ 'HOME.REASONS.' + step + '.SUBTITLE' | translate }}
              </p>
            </div>
            <div class="arrow-image">
              <img *ngIf="i % 2 === 0 && i != '3'" src="../../../assets/images/blue-arrow-down.png"
                alt="seta para baixo" />
              <img *ngIf="i % 2 === 1 && i != '3'" src="../../../assets/images/blue-arrow-up.png"
                alt="seta para cima" />
            </div>
          </div>
        </div>
      </div>
      <swiper [config]="config" class="reasons-content hide-if-desktop-swiper">
        <div class="swiper-wrapper">
          <div class="swiper-slide" *ngFor="let step of ['1', '2', '3', '4']">
            <div class="reasons-step col-sm">
              <a href="javascript:void(0)" (click)="onClick(step)">
                <img loading="lazy" alt="image-reasons" class="reasons-step-img"
                  [src]="'assets/images/reasons_' + step + '.png'" rel="preload" />
                <p class="reasons-step-number mt-2 mb-3">{{ '0' + step }}</p>
                <p class="reasons-step-title">
                  {{ 'HOME.REASONS.' + step + '.TITLE' | translate }}
                </p>
                <p class="reasons-step-subtitle">
                  {{ 'HOME.REASONS.' + step + '.SUBTITLE' | translate }}
                </p>
              </a>
            </div>
          </div>
        </div>
        <div class="swiper-pagination"></div>
      </swiper>
    </div>
  </div>
  <div class="clear">
    <div class="home-content container-fluid tutorial-wrapper">
      <div class="first-row row">
        <h2 class="tutorial-title">
          {{ 'HOME.TUTORIAL.TITLE' | translate }}
        </h2>
      </div>
      <div class="tutorial-content desktop row hide-if-mobile-swiper">
        <div class="tutorial-step" *ngFor="let step of ['1', '2', '3', '4', '5']; let i = index">
          <div class="images-carousel d-flex align-items-center">
            <div class="card-tutorial-step">
              <a href="javascript:void(0)" (click)="onClick(step)">
                <img loading="lazy" alt="image-tutorial" class="tutorial-step-img"
                  [src]="'assets/images/home_tutorial_' + step + '.png'" rel="preload" attr.data-evt-collect="{{
                    'HOME.TUTORIAL.' + step + '.COLLECT' | translate
                  }}" attr.data-evt-category="{{
                    'HOME.TUTORIAL.' + step + '.CATEGORY' | translate
                  }}" attr.data-evt-type="{{
                    'HOME.TUTORIAL.' + step + '.TYPE' | translate
                  }}" attr.data-evt-context-id="{{
                    'HOME.TUTORIAL.' + step + '.CONTEXT_ID' | translate
                  }}" attr.data-evt-element-id="{{
                    'HOME.TUTORIAL.' + step + '.ELEMENT_ID' | translate
                  }}" />
              </a>
              <p class="tutorial-step-number mt-2 mb-3">{{ '0' + step }}</p>
              <p class="tutorial-step-title">
                {{ 'HOME.TUTORIAL.' + step + '.TITLE' | translate }}
              </p>
              <p class="tutorial-step-subtitle">
                {{ 'HOME.TUTORIAL.' + step + '.SUBTITLE' | translate }}
              </p>
            </div>
            <div class="arrow-image">
              <img *ngIf="i % 2 === 0 && i != '4'" src="../../../assets/images/tutorial-arrow-down.png"
                alt="seta para baixo" />
              <img *ngIf="i % 2 === 1 && i != '4'" src="../../../assets/images/tutorial-arrow-up.png"
                alt="seta para cima" />
            </div>
          </div>
        </div>
      </div>
      <swiper [config]="config" class="tutorial-content hide-if-desktop-swiper">
        <div class="swiper-wrapper">
          <div class="swiper-slide" *ngFor="let step of ['1', '2', '3', '4', '5']">
            <div class="tutorial-step col-sm">
              <a href="javascript:void(0)" (click)="onClick(step)">
                <img loading="lazy" alt="image-tutorial" class="tutorial-step-img"
                  [src]="'assets/images/home_tutorial_' + step + '.png'" rel="preload" />
                <p class="tutorial-step-number mt-2 mb-3">{{ '0' + step }}</p>
                <p class="tutorial-step-title">
                  {{ 'HOME.TUTORIAL.' + step + '.TITLE' | translate }}
                </p>
                <p class="tutorial-step-subtitle">
                  {{ 'HOME.TUTORIAL.' + step + '.SUBTITLE' | translate }}
                </p>
              </a>
            </div>
          </div>
        </div>
        <div class="swiper-pagination"></div>
      </swiper>
    </div>
  </div>
  <div class="video-container">
    <div class="row align-center">
      <div class="col-md-6 col-lg-7 col-xl-5 align-center video-image-container">
        <img loading="lazy" class="video-image" src="assets/images/video.webp" alt="video" rel="preload" />
      </div>
      <div class="col-md-6 col-lg-5 col-xl-4 justify-content-start">
        <div class="row video-title no-spaces">
          <h2>{{ 'HOME.VIDEO.TITLE' | translate }}</h2>
        </div>
        <div class="
            row
            col-sm-12 col-md-12 col-lg-10 col-xl-10
            video-subtitle
            no-spaces
          ">
          <h3>{{ 'HOME.VIDEO.SUBTITLE' | translate }}</h3>
        </div>
        <div class="
            row
            col-sm-12 col-md-12 col-lg-10 col-xl-10
            video-subtitle
            no-spaces
          ">
          <h5>{{ 'HOME.VIDEO.TEXT' | translate }}</h5>
        </div>
        <div class="
            row
            col-sm-12 col-md-12 col-lg-10 col-xl-10
            video-subtitle
            no-spaces
          ">
          <h5>{{ 'HOME.VIDEO.SUB-TEXT' | translate }}</h5>
        </div>
        <div class="
            row
            col-sm-12 col-md-12 col-lg-10 col-xl-10
            video-subtitle
            no-spaces
          ">
          <a class="link-about" (click)="dividasButtonGTM()" data-evt-collect="true"
            data-evt-category="f3682a46-8e88-486a-ae4a-1b3ef41f4d38"
            data-evt-type="5e48115d-6ada-4ff5-a140-09b9e963b0b1" data-evt-context-id="buscar_minhas_dividas"
            data-evt-element-id="Buscar minhas dívidas">
            {{ 'HOME.VIDEO.ABOUT' | translate }}
            <img loading="lazy" alt="img" src="assets/images/fi_arrow-up-right.svg" class="about-icon" rel="preload" />
          </a>
        </div>
      </div>
    </div>
  </div>
  <!-- SUBSCRIBE SECTION - START  -->
  <div class="subscribe-container container mb-5">
    <form>
      <div class="subscribe-wrapper d-flex justify-content-between">
        <h3 class="subscribe-clear-title">
          {{ 'HOME.SUBSCRIBE.TITLE' | translate }}
        </h3>
        <br class="hide-if-mobile" />
        <div class="subscribe-cpf-wrapper">
          <app-cpf-cnpj-input idGtag="btn_search_home_bottom" class="app-cpf-cnpj-input"
            (click)="cpfcnpjFooterButtonGTM()"></app-cpf-cnpj-input>
        </div>
      </div>
    </form>
    <!-- SUBSCRIBE - SECTION - QUOD -->
    <!-- <form>
      <div class="subscribe-wrapper d-flex align-center">
        <h3 class="subscribe-clear-title">{{'HOME.SUBSCRIBE_QUOD.TITLE' | translate}}</h3>
        <a target="_blank"
          href="https://cadastro-positivo.vocequod.com.br/?_ga=2.140069747.1565073068.1637248105-1815061848.1637248105"><button
            type="button" class="button primary home-debt-button hide-if-mobile">{{'HOME.SUBSCRIBE_QUOD.BTN-NEXT'
            | translate}}
          </button></a>
        <br class="hide-if-mobile" />
      </div>
    </form> -->
  </div>



  <!-- <div class="banner-section-footer container align-center">
    <a class="image-banner-footer feirao mt-4" (click)="redirectSignUp()">
    </a>
  </div> -->
  <!-- <app-opinion-section></app-opinion-section> -->
</div>
<!-- <section>

  <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 no-spaces">
    <h2 class="home-title channel-section-title pb-4">
      {{"COURT-ORDER-DEBTS.BANNER.SUBTITLE" | translate}}
    </h2>
  </div>
  <div class="grid-container style--grid__in-row-reverse">

    <div class="grid--item-a section__hero">
          <img class="crossFade__image" src="../../../assets/images/carousel-1.svg" />
          <img class="crossFade__image" src="../../../assets/images/carousel-2.svg" />
          <img class="crossFade__image" src="../../../assets/images/carousel-3.svg" />
    </div>
    <div class="grid--item-b style--flex-flow__in-row-reverse">
      <div class="container__text">

        <h2><strong>{{"COURT-ORDER-DEBTS.BANNER.PROPOSE" | translate}}</strong></h2>

        <div class="description">
          <p>{{"COURT-ORDER-DEBTS.BANNER.FIRST-TEXT" | translate}}</p>
          <p>{{"COURT-ORDER-DEBTS.BANNER.SECOND-TEXT" | translate}}</p>
        </div>
        <div class="btn_precatorio">
          <a  [routerLink]="['/precatorios']">{{"COURT-ORDER-DEBTS.BANNER.BUTTON" | translate}}</a>
        </div>
      </div>
    </div>
  </div>
</section> -->
