import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-session-expired',
  templateUrl: './session-expired.component.html',
  styleUrls: ['./session-expired.component.scss']
})
export class SessionExpiredComponent implements OnInit {

  constructor(private activeModal: NgbActiveModal, private router: Router) { }

  ngOnInit(): void {
  }

  close() {
    this.activeModal.close();
  }

  redirectSignUp(){
    this.close();
    this.router.navigate(['/']); 
  }

}
