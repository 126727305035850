<div class="modal-body">
  <div class="body-wrapper feirao">
    <div class="header">
      <div class="d-flex justify-content-end">
        <button class="button-close" (click)="close()">
          <em class="fa fa-times"></em>
        </button>
      </div>
      <a>
        <img
          loading="lazy"
          src="assets/images/feirao/Pop-up-entrada.png"
          alt="video"
          rel="preload"
        />
      </a>
    </div>
  </div>
</div>
