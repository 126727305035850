import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import decode from 'jwt-decode';
import { SocialAuthService } from 'angularx-social-login';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  private currentUserSubject: BehaviorSubject<any>;
  public currentUser: Observable<any>;
  public redirectUrl: string;

  constructor(private http: HttpClient) {
    this.currentUserSubject = new BehaviorSubject<any>(
      JSON.parse(sessionStorage.getItem('returnIpanema'))
    );

    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): any {
    return this.currentUserSubject.value;
  }

  getUserLoggedIn(): Observable<boolean> {
    let currentUser = JSON.parse(sessionStorage.getItem('returnIpanema'));

    if (!currentUser) { }

    return this.currentUserSubject.asObservable();
  }

  login(data) {
    return this.http.post<any>(
      `${environment.apiUrl}/accounts/auth`,
      data
    ).pipe(map((user: any) => {
      sessionStorage.setItem('returnIpanema', JSON.stringify(user));
      this.currentUserSubject.next(user);

      return user;
    }));
  }

  logout() {
    sessionStorage.removeItem('returnIpanema');
    sessionStorage.removeItem('returnIpanemaSession');
    sessionStorage.removeItem('returnIpanemaOnboarding');
    sessionStorage.removeItem('loginProvider');
    this.currentUserSubject.next(null);
  }

  async getTokenInfo() {
    let token: any = JSON.parse(sessionStorage.getItem('returnIpanema'));

    if (token) {
      return decode(token.Token);
    }

    return null;

  }

  getToken() {
    let token: any = JSON.parse(sessionStorage.getItem('returnIpanema'));

    if (token) {
      return token;
    }

    return null;

  }

  async getCustomerInfo() {
    let token: any = JSON.parse(sessionStorage.getItem('returnIpanema'));

    if (token) {
      return token.CustomerId;
    }

    return null;

  }

  isExpired() {
    let token: any = JSON.parse(sessionStorage.getItem('returnIpanema'));

    if (token) {
      let tokenInfo: any = decode(token.Token);

      return (Math.floor((new Date).getTime() / 1000)) >= tokenInfo.exp;
    }

    return true;
  }
}
