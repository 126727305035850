<!-- FOOTER DESKTOP -->
<footer class="hide-if-mobile">
  <div class="container">
    <div class="row">
      <div class="col-md-6 col-lg-6 col-xs-12 col-sm-12 footer-div-items">
        <img style="margin: -30px 0 0 0" src="../../../../assets/images/logo-footer.svg" alt="Ipanema" rel="preload" />
        <div *ngIf="screenWidth >= 993" id="reputation-ra" #ra></div>
        <p class="address">
          Rua Alves Guimarães, 1212<br>
          Pinheiros - São Paulo/SP - 05410-002
        </p>
      </div>
      <div class="col-md-6 col-lg-6 col-xs-12 col-sm-12">
        <div class="row">
          <div class="col-md-6 col-lg-6 col-xs-12 col-sm-12">
            <ul class="nav flex-column footer-div-items">
              <li class="nav-item">
                <a class="nav-link" routerLink="/painel/dividas" (click)="consultarLinkGTM()" data-evt-collect="true"
                  data-evt-category="f3682a46-8e88-486a-ae4a-1b3ef41f4d38"
                  data-evt-type="4f117429-4a95-4bae-b603-8dd3dc74ccee" data-evt-context-id="dividas"
                  data-evt-element-id="Negociar suas dívidas">{{ 'FOOTER.NEGOTIATE' | translate }}</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" routerLink="/painel/acordos" (click)="viaLinkGTM()" data-evt-collect="true"
                  data-evt-category="f3682a46-8e88-486a-ae4a-1b3ef41f4d38"
                  data-evt-type="18bde62f-bc03-4c32-96fe-bf58df7b81c4" data-evt-context-id="segunda_via"
                  data-evt-element-id="2a via de boleto">{{ 'FOOTER.TICKET' | translate }}</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" routerLink="/painel/acordos" (click)="acordosLinkGTM()" data-evt-collect="true"
                  data-evt-category="f3682a46-8e88-486a-ae4a-1b3ef41f4d38"
                  data-evt-type="5e48115d-6ada-4ff5-a140-09b9e963b0b1" data-evt-context-id="acordos"
                  data-evt-element-id="Ver acordos">{{ 'FOOTER.ARRANGEMENTS' | translate }}</a>
              </li>
              <li class="nav-item" *ngIf="!isLogged">
                <a class="nav-link" routerLink="cadastrar" (click)="cadastrarLinkGTM()" data-evt-collect="true"
                  data-evt-category="f3682a46-8e88-486a-ae4a-1b3ef41f4d38"
                  data-evt-type="5e48115d-6ada-4ff5-a140-09b9e963b0b1" data-evt-context-id="cadastrar"
                  data-evt-element-id="Cadastrar">{{ 'FOOTER.SIGNUP' | translate }}</a>
              </li>
              <li class="nav-item" *ngIf="!isLogged">
                <a class="nav-link" routerLink="entrar" (click)="entrarLinkGTM()" data-evt-collect="true"
                  data-evt-category="f3682a46-8e88-486a-ae4a-1b3ef41f4d38"
                  data-evt-type="5e48115d-6ada-4ff5-a140-09b9e963b0b1" data-evt-context-id="entrar"
                  data-evt-element-id="Entrar">{{ 'FOOTER.SIGNIN' | translate }}</a>
              </li>
              <li class="nav-item">
                <a class="last-line-item" routerLink="termos-de-uso" data-evt-collect="true"
                  data-evt-category="d1273286-0cb8-46c9-9ae6-b7c35c680975"
                  data-evt-type="5e48115d-6ada-4ff5-a140-09b9e963b0b1" data-evt-context-id="termos"
                  data-evt-element-id="Termos e condições">{{ 'FOOTER.TERMS' | translate }}</a>
              </li>
              <li class="nav-item">
                <a class="last-line-item" routerLink="politica-de-cookies" data-evt-collect="true"
                  data-evt-category="d1273286-0cb8-46c9-9ae6-b7c35c680975"
                  data-evt-type="5e48115d-6ada-4ff5-a140-09b9e963b0b1" data-evt-context-id="termos"
                  data-evt-element-id="Termos e condições">{{ 'FOOTER.COOKIES-POLICY' | translate }}</a>
              </li>
            </ul>
          </div>
          <div class="col-md-6 col-lg-6 col-xs-12 col-sm-12">
            <ul class="nav flex-column footer-div-items">
              <li class="nav-item">
                <a id="btn_search_about_bottom" class="nav-link" routerLink="sobre-ipanema" (click)="aboutLinkGTM()"
                  data-evt-collect="true" data-evt-category="d1273286-0cb8-46c9-9ae6-b7c35c680975"
                  data-evt-type="5e48115d-6ada-4ff5-a140-09b9e963b0b1" data-evt-context-id="about"
                  data-evt-element-id="Sobre nós">{{ 'FOOTER.ABOUT' | translate }}</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" routerLink="contato" (click)="contactLinkGTM()" data-evt-collect="true"
                  data-evt-category="d1273286-0cb8-46c9-9ae6-b7c35c680975"
                  data-evt-type="5e48115d-6ada-4ff5-a140-09b9e963b0b1" data-evt-context-id="contato"
                  data-evt-element-id="Contato">{{ 'FOOTER.CONTACT' | translate }}</a>
              </li>
              <!-- Link QUOD no Foote -->
              <!-- <li class="nav-item">
                <a class="nav-link" target="_blank"
                  href="https://cadastro-positivo.vocequod.com.br/?_ga=2.140069747.1565073068.1637248105-1815061848.1637248105">
                  {{'FOOTER.CONSULT' | translate}}</a>
              </li> -->
              <li class="nav-item">
                <a class="last-line-item" routerLink="" (click)="openModalPrivacy()" data-evt-collect="true"
                  data-evt-category="d1273286-0cb8-46c9-9ae6-b7c35c680975"
                  data-evt-type="5e48115d-6ada-4ff5-a140-09b9e963b0b1" data-evt-context-id="privacy"
                  data-evt-element-id="Central de privacidade">{{ 'FOOTER.PRIVACY' | translate }}</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="row copyright">
      <div class="col-md-9 col-lg-9 col-sm-12 col-xs-12 copyright">
        &copy; {{ year }} Fundo de Investimento em Direitos Creditórios
        Multisegmentos NPL Ipanema VI <br>CNPJ: 26.405.883/0001-03
      </div>
      <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12 social-bar copyright">
        <ul class="nav flex-row footer-social-items">
          <li class="nav-item">
            <a target="_blank" rel="noopener noreferrer" class="nav-link" id="lnk_facebook" (click)="facebookLinkGTM()"
              href="https://www.facebook.com/negocie.ipanema">
              <img class="facebook" src="../../../../assets/images/ic_facebook.svg" data-evt-collect="true"
                data-evt-category="d1273286-0cb8-46c9-9ae6-b7c35c680975"
                data-evt-type="d2046231-877e-467c-b054-f05ff1a3b59a" data-evt-context-id="facebook"
                data-evt-element-id="Facebook" alt="Facebook" rel="preload" />
            </a>
          </li>
          <li class="nav-item">
            <a target="_blank" rel="noopener noreferrer" class="nav-link" id="lnk_youtube" (click)="youtubeLinkGTM()"
              href="https://youtu.be/nd6ozOn7eqY">
              <img class="youtube" src="../../../../assets/images/ic_youtube.svg" data-evt-collect="true"
                data-evt-category="d1273286-0cb8-46c9-9ae6-b7c35c680975"
                data-evt-type="d2046231-877e-467c-b054-f05ff1a3b59a" data-evt-context-id="youtube"
                data-evt-element-id="Youtube" alt="Youtube" rel="preload" />
            </a>
          </li>
          <li class="nav-item">
            <a target="_blank" rel="noopener noreferrer" class="nav-link" id="lnk_instagram"
              (click)="instagramLinkGTM()" href="https://instagram.com/negocieipanema?utm_medium=copy_link">
              <em class="fa fa-instagram fa-2x social-icon" data-evt-collect="true"
                data-evt-category="d1273286-0cb8-46c9-9ae6-b7c35c680975"
                data-evt-type="d2046231-877e-467c-b054-f05ff1a3b59a" data-evt-context-id="instagram"
                data-evt-element-id="Instagram"></em>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</footer>

<!-- FOOTER MOBILE -->
<footer class="hide-if-desktop">
  <div class="row">
    <div class="col-8">
      <a class="nav-link" routerLink="/painel/dividas" (click)="consultarLinkGTM()">{{ 'FOOTER.NEGOTIATE' | translate
        }}</a>
    </div>
    <div class="col-4">
      <a class="nav-link" routerLink="/sobre-ipanema" (click)="viaLinkGTM()">{{
        'FOOTER.ABOUT' | translate
        }}</a>
    </div>
    <div class="col-8 mt-20">
      <a class="nav-link" routerLink="/painel/acordos" (click)="acordosLinkGTM()">{{ 'FOOTER.TICKET' | translate }}</a>
    </div>
    <div class="col-4 mt-20">
      <a class="nav-link" routerLink="/contato" (click)="contactLinkGTM()">{{
        'FOOTER.CONTACT' | translate
        }}</a>
    </div>
    <div class="col-6 mt-20" *ngIf="!isLogged">
      <a class="nav-link" routerLink="/cadastrar" (click)="cadastrarLinkGTM()">{{ 'FOOTER.SIGNUP' | translate }}</a>
    </div>
  </div>

  <div class="row mt-56">
    <div class="col-12 pt-2 pb-2">
      <a class="last-line-item" routerLink="termos-de-uso">{{
        'FOOTER.TERMS' | translate
        }}</a>
    </div>
  </div>
  <div class="row mt-56">
    <div class="col-6">
      <a class="last-line-item" routerLink="politica-de-cookies">{{
        'FOOTER.COOKIES-POLICY' | translate
        }}</a>
    </div>
    <div class="col-6">
      <a class="last-line-item" routerLink="" (click)="openModalPrivacy()">{{
        'FOOTER.PRIVACY' | translate
        }}</a>
    </div>
  </div>


  <div class="row container-logo">
    <div class="col-12">
      <img src="../../../../assets/images/logo-footer-mobile.svg" alt="Ipanema" rel="preload" />
      <div *ngIf="screenWidth < 993" id="reputation-ra" #ra></div>
      <p class="address">
        Rua Alves Guimarães, 1212<br>
        Pinheiros - São Paulo/SP - 05410-002
      </p>
    </div>
  </div>

  <div class="row copyright">
    <div class="col-md-9 col-lg-9 col-sm-12 col-xs-12 copyright">
      &copy; {{ year }} Fundo de Investimento em Direitos Creditórios
      Multisegmentos NPL Ipanema VI
      <br>CNPJ: 26.405.883/0001-03
    </div>
    <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12 social-bar copyright">
      <ul class="nav flex-row footer-social-items">
        <li class="nav-item">
          <a target="_blank" rel="noopener noreferrer" class="nav-link" id="lnk_facebook"
            href="https://www.facebook.com/negocie.ipanema" (click)="facebookLinkGTM()">
            <img class="facebook" src="../../../../assets/images/ic_facebook.svg" alt="Facebook" rel="preload" />
          </a>
        </li>
        <li class="nav-item">
          <a target="_blank" rel="noopener noreferrer" class="nav-link" id="lnk_youtube"
            href="https://youtu.be/nd6ozOn7eqY" (click)="youtubeLinkGTM()">
            <img class="youtube" src="../../../../assets/images/ic_youtube.svg" alt="Youtube" rel="preload" />
          </a>
        </li>
        <li class="nav-item">
          <a target="_blank" rel="noopener noreferrer" class="nav-link" id="lnk_instagram"
            href="https://instagram.com/negocieipanema?utm_medium=copy_link" (click)="instagramLinkGTM()">
            <em class="fa fa-instagram fa-2x social-icon"></em>
          </a>
        </li>
      </ul>
    </div>
  </div>
</footer>