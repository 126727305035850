import { Component, OnInit, Input } from '@angular/core';
import { AccountService } from '../../../services/account.service';
import { ToastService } from '../../../services/toast.service';
import { Router } from '@angular/router';
import { cpf, cnpj } from 'cpf-cnpj-validator';
import { CpfCnpjPipe } from '../../utils/pipes/cpfCnpj.pipe';

@Component({
  selector: 'app-cpf-cnpj-input-mobile',
  templateUrl: './cpf-cnpj-input-mobile.component.html',
  styleUrls: ['./cpf-cnpj-input-mobile.component.scss'],
  providers: [CpfCnpjPipe],
})
export class CpfCnpjInputMobileComponent implements OnInit {
  @Input() idGtag = '';
  loading = false;
  cpf: string;
  cpfTwo: string;
  disabled = false;
  mask: string;

  constructor(
    private accountService: AccountService,
    private toastService: ToastService,
    private router: Router,
    private cpfCnpjPipe: CpfCnpjPipe
  ) { }

  ngOnInit(): void { }

  maskInput(ev) {
    var masks = ['000.000.000-000', '00.000.000/0000-00'];
    this.mask = ev.target.value.length > 14 ? masks[1] : masks[0];
  }

  validate() {
    let cpfReplaced = this.cpf.replace(/[^0-9]/g, '');
    if (cpfReplaced.length == 11) {
      if (!cpf.isValid(cpfReplaced)) {
        this.toastService.show({
          body: 'O CPF informado é inválido.',
          class: 'bg-danger',
        });

        this.disabled = true;
      } else {
        this.disabled = false;
      }
    } else if (cpfReplaced.length == 14) {
      if (!cnpj.isValid(cpfReplaced)) {
        this.toastService.show({
          body: 'O CNPJ informado é inválido.',
          class: 'bg-danger',
        });

        this.disabled = true;
      } else {
        this.disabled = false;
      }
    } else {
      this.disabled = true;
    }
  }

  getByCpf(cpfCnpj) {
    this.loading = true;
    sessionStorage.setItem('IpanemaCpfCnpj', cpfCnpj);
    this.accountService.getResponse({ Username: cpfCnpj }).subscribe(
      (ret: any) => {
        this.loading = false;
        if (ret.status === 200) {
          this.router.navigate(['entrar'], {
            state: {
              new: ret.body.MigratedUser,
              cpfCnpj: ret.body.Username,
              name: ret.body.Name,
            },
          });
        } else if (ret.status === 204) {
          this.router.navigate(['cadastrar'], {
            state: { newCpfCnpj: cpfCnpj, new: true },
          });
        }
      },
      (err) => {
        this.loading = false;
        //TODO: tornar a variável new dinâmica
        this.router.navigate(['cadastrar'], {
          state: { new: true, cpfCnpj: cpfCnpj },
        });
      }
    );
  }
}
