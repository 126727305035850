import { AuthenticationService } from '../services/authentication.service';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  private FIBBIA_URL = `${environment.apiApolo}/api/event-collector/events/`;
  constructor(private authenticationService: AuthenticationService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const currentUser = this.authenticationService.getToken();

    if (request.url == this.FIBBIA_URL) {
      let headers: any = '';

      request = request.clone({
        setHeaders: headers,
      });

      return next.handle(request);
    }
    // var apiKey =
    //   request.url.indexOf(environment.apiReturnTeste) != -1
    //     ? environment.returnApiKey.key
    //     : environment.API_KEY;

    let api_header = { 'x-api-key': environment.API_KEY };

    let headers: any = api_header;


    if (
      currentUser &&
      currentUser.Token
    ) {

      headers.Authorization = `Bearer ${currentUser.Token}`;
    }

    request = request.clone({
      setHeaders: headers,
    });

    return next.handle(request);
  }
}
