import {Component, Input, Output, EventEmitter} from '@angular/core';

@Component({
  selector: 'app-menu-item',
  templateUrl: './menu-item.component.html',
  styleUrls: ['menu-item.component.scss']
})
export class MenuItemComponent {

  @Input() menuConfig = { identifier: '', isPrimary: false };

  @Input() text = '';

  @Input() route = '';

  @Input() identifier = '';

  @Input() isButton = false;

  @Input() dropdown = false;

  @Input() items = [];

  @Input() buttonStyle = '';

  @Input() class = '';

  @Input() isPrimary = true;

  @Input() show = true;

  @Input() idGtag = '';

  @Input() fibbia_evt_collector = '';

  @Input() fibbia_evt_category = '';

  @Input() fibbia_evt_type = '';

  @Input() fibbia_evt_context_id = '';

  @Input() fibbia_evt_element_id = '';
  
  @Output() toggled = new EventEmitter<boolean>();

  setHide(){
      this.toggled.emit(false);
  }
}
