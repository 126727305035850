<div class="modal-body">
  <div class="body-wrapper-popup feirao">
    <div class="header-popup">
      <div class="d-flex justify-content-end">
        <button class="button-close-popup" (click)="close()">
          <em class="fa fa-times"></em>
        </button>
      </div>
      <a (click)="redirectSignUp()">
        <img
          loading="lazy"
          src="./assets/images/feirao/Pop-up-saida.png"
          alt="video"
          rel="preload"
        />
      </a>
    </div>
  </div>
</div>
