import { Injectable, Injector } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastService } from './toast.service';
@Injectable({
  providedIn: 'root',
})
export class ErrorService {
  constructor(private injector: Injector, private toastService: ToastService) { }

  translatedErros(error) {
    let translate = this.injector.get(TranslateService);
    translate.get(error).subscribe((res: string) => {
      if (error === res) {
        let messageDefault = `HTTP-ERRORS.ERROR-CODE.DEFAULT`;
        this.translatedErros(messageDefault);
      } else {
        this.showToast(res);
      }
    });
  }

  showToast(message) {
    this.toastService.show({
      body: message,
      class: 'bg-danger',
    });
  }
}
