import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { PublicAppComponent } from './public/app/app.component';
import { PublicHomeComponent } from './public/home/home.component';

export const routes: Routes = [
  // default route
  { path: '', pathMatch: 'full', component: PublicAppComponent, children: [{ path: '', component: PublicHomeComponent }]},
  // Public routes
  { path: '', loadChildren: () => import('src/app/public/public-area.module').then(m => m.PublicAreaModule) },
  // Logged routes
  { path: '', loadChildren: () => import('src/app/logged/logged.module').then(m => m.LoggedModule) },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule {

}
