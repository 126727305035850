<div class="row banner-cookies" hidden="{{ hidden }}">
  <div class="col-md-8 col-lg-8 col-xs-12 col-sm-12 align-self-center">
    {{ 'BANNER.TEXT' | translate }}
    <strong><a class="default-link" href="../../assets/files/termos-de-uso-ipanema.pdf" target="_blank">{{
        'BANNER.BANNER-POLICY' | translate
        }}</a>
      | <a class="default-link" href="../../assets/files/politica-de-cookies-ipanema.pdf" target="_blank">{{
        'BANNER.COOKIE-POLICY' | translate
        }}</a>
    </strong>
  </div>
  <div class="col-md-4 col-lg-4 col-xs-12 col-sm-12 mt-2">
    <button type="button" class="light mr-2" (click)="reject()">
      {{ 'BANNER.BUTTON.UNACCEPT' | translate }}
    </button>
    <button type="button" class="primary mr-2" (click)="accept()">
      {{ 'BANNER.BUTTON.ACCEPT' | translate }}
    </button>
    <strong class="ml-2">
      <strong><a class="default-link" href="#" (click)="openModalPrivacy()">{{
          'BANNER.CONFIG' | translate
          }}</a></strong>
    </strong>
  </div>
</div>