import { Injectable } from '@angular/core';
import { EMPTY, from, of } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { AbstractService } from './abstract.service';

@Injectable({
  providedIn: 'root'
})
export class AccountService extends AbstractService {

  model = 'accounts';

  signup(data): any {
    return this.http.post(`${environment.apiUrl}/${this.model}`, data);
  }

  confirmOnboarding(): any {
    return this.http.patch(`${environment.apiUrl}/${this.model}/onboarding`, {});
  }

  validateSms(data): any {
    return this.http.patch(`${environment.apiUrl}/${this.model}/sms/validation`, data);
  }

  resendSms(data): any {
    return this.http.post(`${environment.apiUrl}/${this.model}/sms/validation/resend`, data);
  }

  // --------------------------------------- Recovery --------------------------------------- //

  getUserData(data): any {
    return this.http.post(`${environment.apiUrl}/${this.model}/recovery`, data).toPromise();
  }

  forgotPassword(data): any {
    return this.http.put(`${environment.apiUrl}/${this.model}/password`, data, { observe: 'response' });
  }

  sendNewPassword(data): any {
    return this.http.patch(`${environment.apiUrl}/${this.model}/password`, data);
  }
}
