<ul class="ul-menu" *ngIf="!dropdown">
  <li
    class="nav-item font-normal"
    [ngClass]="{
      'nav-button menu-button-custom': isButton,
      'btn btn-primary': buttonStyle && buttonStyle.length > 0
    }"
  >
    <a
    id="nav-common"
      class="nav-link {{ class }}"
      [ngClass]="{
        active: menuConfig.identifier == identifier,
        'nav-primary': isPrimary,
        'nav-secondary': !isPrimary
      }"
      [routerLink]="route"
      tabindex="0"
      (click)="setHide()"
      [id]="idGtag"
      attr.data-evt-collect="{{ fibbia_evt_collector }}"
      attr.data-evt-category="{{ fibbia_evt_category }}"
      attr.data-evt-type="{{ fibbia_evt_type }}"
      attr.data-evt-context-id="{{ fibbia_evt_context_id }}"
      attr.data-evt-element-id="{{ fibbia_evt_element_id }}"
    >
      {{ text | translate }}
    </a>
    <span
      class="circle-active"
      *ngIf="menuConfig.identifier == identifier"
    ></span>
  </li>
</ul>

<ul class="ul-menu" *ngIf="dropdown">
  <li ngbDropdown class="nav-item dropdown">
    <a
      class="nav-link dropdown-toggle"
      href="javascript:void(0)"
      (keyDown)="('javascript:void(0)')"
      id="navbarDropdown"
      role="button"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
      ngbDropdownToggle
    >
      {{ text | translate }}
    </a>

    <div class="dropdown-menu" aria-labelledby="navbarDropdown" ngbDropdownMenu>
      <a
        *ngFor="let item of items"
        class="dropdown-item"
        ngbDropdownItem
        (click)="setHide()"
        attr.data-evt-collect="{{ item.fibbia_evt_collector }}"
        attr.data-evt-category="{{ item.fibbia_evt_category }}"
        attr.data-evt-type="{{ item.fibbia_evt_type }}"
        attr.data-evt-context-id="{{ item.fibbia_evt_context_id }}"
        attr.data-evt-element-id="{{ item.fibbia_evt_element_id }}"
        routerLink="{{ item.route }}"
        >{{ item.text | translate }}</a
      >
    </div>
  </li>
</ul>
