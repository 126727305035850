export const environment = {
  production: true,
  apiUrl: 'https://api.ipanemacm.com.br/v1',
  apiUrlV2: 'https://api.ipanemacm.com.br/v2',
  API_KEY: 'rTdkGWYqPM5haN4noJKL724gUMpzuIvu4uja2mlI',
  gtmId: 'GTM-MXDCVLP',
  bugsnagApiKey: 'e36e34e8fa6dfe472f3b0eb21126d1a1',
  googleAppId: '',
  facebookAppId: '',
  IdeTimeOut: 1800,
  GTMMetricId: 'G-X9J4JETEEP',
  firebaseID: 'G-WW6VEE4D9F',
  universalAnalytics: 'UA-147966704-7',
  DiasLimitePagamento: 5,
  apiApolo: 'https://apollo.returncapital.com.br',
};
