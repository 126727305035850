import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CookieService } from 'src/app/services/cookie.service';
import { Cookie } from '../../models/cookie.model';
import { PrivacyCenterComponent } from '../privacy-center/privacy-center.component';

@Component({
  selector: 'app-banner-lgpd',
  templateUrl: './banner-lgpd.component.html',
  styleUrls: ['./banner-lgpd.component.scss']
})
export class BannerLgpdComponent implements OnInit {

  hidden: Cookie;
  constructor(private modalService: NgbModal, private cookieService: CookieService) { }

  ngOnInit() {
    this.hidden = this.cookieService.getCookie();
    this.cookieService.cookieSaved.subscribe(event => {
      this.hidden = event;
    });
  }

  accept(): void {
    this.cookieService.setAcceptAll();
  }

  reject(): void {
    this.cookieService.setRejectAll();
  }

  openModalPrivacy() {
    this.modalService.open(PrivacyCenterComponent,
      { windowClass: 'gr-modal-full', backdrop: false });
  }

}