<div class="modal-body">
  <div class="body-wrapper-popup">
    <div class="header-popup">
      <div class="d-flex justify-content-end">
        <button class="button-close-popup" (click)="redirectSignUp()">
          <em class="fa fa-times"></em>
        </button>
      </div>
      <div class="d-flex justify-content-center">
        <a (click)="redirectSignUp()">
          <img loading="lazy" src="./assets/images/alert.png" />
          <label><strong>Sessão expirada por inatividade!</strong></label>
        </a>
      </div>
    </div>
  </div>
</div>
