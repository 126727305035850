import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

declare const ApolloEventCollector: any;

@Injectable({
  providedIn: 'root',
})
export class ApoloService {
  public token: any;
  public apollo: any;

  constructor(protected http: HttpClient) { }

  getToken() {
    return this.http.get<any>(`${environment.apiUrl}/fibbia`);
  }

  postData(http: HttpClient, uri: any, body: any, headers: any): any {
    return http.post(uri, body, headers).subscribe();
  }

  getEvent(token, post, http) {
    return {
      events: ['mouseup', 'change'],
      onEvent: function (evt) {
        const uri = `${environment.apiApolo}/api/event-collector/events/`;
        const headers = { Authorization: 'Bearer ' + token };
        const cookies = JSON.parse(sessionStorage.getItem('CookiePortalIpan'));
        const logged = JSON.stringify(sessionStorage.getItem('returnIpanemaSession'));
        const body = {
          channel: evt.channel,
          category: evt.category,
          page: evt.page,
          event_type: evt.eventType,
          element_type: evt.elementType,
          element: evt.rawEvent.target.dataset.evtElementId,
          event_name: evt.eventName,
          full_url: evt.fullURL,
          context_id: evt.contextId,
          context_data: evt.contextData,
          sendHeaders: cookies == null ? '1' : (cookies.RejectAll == true && logged == 'null') ? '0' : '1'
        };
        post(http, uri, body, { headers });
      },

      dynamicContextProcessor: function (e) {
        return {
          contextId: e.dataset.evtContextId || e.dataset.evtElementId,
          contextData: e.value || e.id,
        };
      },

    };
  }

  sendManualEvent(category, eventType, elementType, evtElementId, eventName, contextId, contextData) {
    const token = this.token;
    const post = this.postData;
    const http = this.http;
    const uri = `${environment.apiApolo}/api/event-collector/events/`;
    const headers = { Authorization: 'Bearer ' + token };

    const body = {
      channel: document.all[0].getAttribute('data-evt-channel'),
      category: category,
      page: document.all[0].getAttribute('data-evt-page'),
      event_type: eventType,
      element_type: elementType,
      element: evtElementId,
      event_name: eventName,
      full_url: document.location.href,
      context_id: contextId,
      context_data: contextData,

    };

    post(http, uri, body, { headers });
  }

  async sendData() {
    this.token = null;
    this.token = await this.getToken().toPromise();
    let evennt = this.getEvent(this.token, this.postData, this.http);
    console.log(evennt)
    if (typeof this.apollo === 'undefined') {
      this.apollo = new ApolloEventCollector(evennt);
      this.apollo.start();
    } else {
      this.apollo = new ApolloEventCollector(evennt);
    }
  }
}
