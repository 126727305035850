import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';
import { ToastService } from './services/toast.service';
import { PopUpOutputComponent } from './shared/components/pop-up-output/pop-up-output.component';
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';

//Idle
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { Router } from '@angular/router';
import { AuthenticationService } from './services/authentication.service';
import { SessionExpiredComponent } from './public/session-expired/session-expired.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'portal-ipanema';
  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date = null;

  constructor(
    private idle: Idle,
    private keepalive: Keepalive,
    private router: Router,
    private authenticationService: AuthenticationService,
    private modalService: NgbModal,
    private http: HttpClient
  ) {
    // Obriga o usuário a logar na aplicação
    // this.authenticationService.logout();

    idle.setIdle(5);
    idle.setTimeout(environment.IdeTimeOut);
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    idle.onIdleEnd.subscribe(() => {
      this.idleState = 'Não ocioso';
      //console.log(this.idleState);
      this.reset();
    });

    idle.onTimeout.subscribe(() => {
      this.idleState = 'Timed out!';
      this.timedOut = true;
      //console.log(this.idleState);
      this.authenticationService.logout();
      this.openPopUpSession();
    });

    idle.onIdleStart.subscribe(() => {
      this.idleState = 'Ocioso!';
      //console.log(this.idleState);
    });

    idle.onTimeoutWarning.subscribe((countdown) => {
      this.idleState = 'Você será deslogado em ' + countdown;
      //console.log(this.idleState);
    });

    keepalive.interval(15);
    keepalive.onPing.subscribe(() => (this.lastPing = new Date()));

    this.authenticationService.getUserLoggedIn().subscribe((userLoggedIn) => {
      if (userLoggedIn) {
        idle.watch();
        this.timedOut = false;
      } else {
        idle.stop();
      }
    });
  }

  ngOnInit() {
    let mouseY = 0;
    let showMessage = false;
    document.body.addEventListener('mousemove', function (e) {
      mouseY = e.clientY;
    });

    document.body.onmouseleave = () => {
      if (mouseY < 100) {
        if (!showMessage) {
          // this.openPopUp();
        }
        showMessage = true;
      }
    };

    this.loadFirebase();
  }

  public loadFirebase() {
    const firebaseConfig = {
      apiKey: 'AIzaSyC9tiObMYCAAUZfE9U4L3MmjDrj3XWD3OE',
      authDomain: 'ipanema-1631806924705.firebaseapp.com',
      projectId: 'ipanema-1631806924705',
      storageBucket: 'ipanema-1631806924705.appspot.com',
      messagingSenderId: '243042581999',
      appId: '1:243042581999:web:795aacc2790af681488a1a',
      measurementId: 'G-WW6VEE4D9F',
    };

    // Initialize Firebase
    const app = initializeApp(firebaseConfig);
    const analytics = getAnalytics(app);
  }

  openPopUp() {
    this.modalService.open(PopUpOutputComponent, {
      centered: true,
      backdrop: false,
    });
  }

  reset() {
    this.idle.watch();
    //xthis.idleState = 'Started.';
    this.timedOut = false;
  }

  openPopUpSession() {
    this.modalService.open(SessionExpiredComponent, {
      centered: true,
      backdrop: false,
    });
  }
}
