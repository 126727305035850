import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {MenuComponent} from './menu/menu.component';
import {NgbDropdownModule} from '@ng-bootstrap/ng-bootstrap';
import {RouterModule} from '@angular/router';
import {MenuItemComponent} from './menu/menu-item/menu-item.component';
import {TranslateModule} from '@ngx-translate/core';
import {DropdownComponent} from './menu/dropdown/dropdown.component';
import {ToggleButtonComponent} from './menu/toggle-button/toggle-button.component';
import {FooterComponent} from './footer/footer.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgbDropdownModule,
    RouterModule,
    TranslateModule
  ],
  declarations: [
    MenuComponent,
    MenuItemComponent,
    DropdownComponent,
    ToggleButtonComponent,
    FooterComponent
  ],
  exports: [
    MenuComponent,
    FooterComponent
  ]
})
export class  PublicModule {

}
