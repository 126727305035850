import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CookieConstant } from '../shared/cookie.constant';
import { Cookie } from '../shared/models/cookie.model';
import { AbstractService } from './abstract.service';
import { PrivacyService } from './privacy.service';

@Injectable({
  providedIn: 'root'
})
export class CookieService {
  model = 'cookie';
  cookie: Cookie;
  cookieSaved: EventEmitter<Cookie> = new EventEmitter<Cookie>();

  constructor(protected http: HttpClient, private privacyService: PrivacyService) {
    this.setCookie();
  }

  async getIP(): Promise<any> {
    let ipAddress = await fetch('https://ipapi.co/json?api-key=test');
    return ipAddress.text();
  }

  async setCookie() {
    this.cookie = {
      Name: CookieConstant.nameCookie,
      AcceptAll: null,
      RejectAll: null,
      Custom: {
        Advertising: false,
        Analytics: false,
        Essential: true,
        Performance: false,
        Thirdparties: false
      }
    }
  }

  setAcceptAll() {
    this.cookie.AcceptAll = true;
    this.cookie.RejectAll = false;
    this.cookie.Custom = null;

    this.storeCookie();
  }

  setRejectAll() {
    this.cookie.AcceptAll = false;
    this.cookie.RejectAll = true;
    this.cookie.Custom = null;

    this.storeCookie();
  }

  setCustomCookie(performance: boolean, analytics: boolean, advertising: boolean, essential: boolean, thirdparties: boolean) {
    this.setCookie();
    this.cookie.Custom = {
      Performance: performance,
      Analytics: analytics,
      Advertising: advertising,
      Essential: essential,
      Thirdparties: thirdparties
    };

    //Analytics if enabled = disable script    
    window['ga-disable-UA-147966704-3'] = !analytics;
    window['ga-disable-G-3RJW5SYBCM'] = !analytics;
    this.storeCookie();
  }

  getCookie(): Cookie {
    let cookie = sessionStorage.getItem(CookieConstant.nameCookie);
    return cookie != null ? JSON.parse(cookie) : cookie;
  }

  async storeCookie() {
    sessionStorage.setItem(CookieConstant.nameCookie, JSON.stringify(this.cookie));
    await this.fillCookieLog();
    this.saveCookieLog();
    this.cookieSaved.emit(this.cookie);
  }

  async fillCookieLog() {
    let cookieLog = this.cookie;

    let info = await this.getIP();
    info = info != null ? JSON.parse(info) : info;
    cookieLog.IpOrigin = info.ip;
    cookieLog.CityOrigin = info.city;
    cookieLog.Navigator = navigator.userAgent;
  }

  saveCookieLog() {
    let options = { Encoded: window.btoa(JSON.stringify(this.cookie)) };
    this.privacyService.save(options).subscribe();
  }
}
