import { AuthenticationService } from '../services/authentication.service';
import { catchError } from 'rxjs/operators';
import { EMPTY } from 'rxjs';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { observable, Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { ErrorService } from '../services/error.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  private FIBBIA_URL = `${environment.apiApolo}/api/event-collector/events/`;
  private FIBBIA = `${environment.apiUrl}/fibbia`;
  private PIXURL = `/pix/checkpayment`;
  private PRIVACYURL = `/privacy/save`;


  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private errorService: ErrorService
  ) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err) => {
        if (request.url.includes(this.FIBBIA_URL) || request.url.includes(this.FIBBIA) || request.url.includes(this.PRIVACYURL)) {
          return EMPTY;
        }

        if (request.url.includes(this.PIXURL)) {
          if (err?.error?.ErrorCode == "INTERNAL_SERVER_ERROR") {
            return EMPTY;
          }
        }

        this.errorService.translatedErros(
          `HTTP-ERRORS.ERROR-CODE.${err?.error?.ErrorCode}`
        );

        if (err.status === 401) {
          this.authenticationService.logout();
          this.router.navigate(['entrar']);
        }

        if (err.status === 403) {
          this.router.navigate(['painel']);
        }

        const error = err;
        return throwError(error);
      })
    );
  }
}
