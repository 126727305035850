import { Component, OnInit, ViewChild } from '@angular/core';
import { MenuInitService } from '../../shared/utils/service/menu-init.service';
import { SwiperOptions } from 'swiper';
import { Router } from '@angular/router';
import {
  NgbCarouselConfig,
  NgbCarousel,
  NgbModal,
} from '@ng-bootstrap/ng-bootstrap';
import { PopUpComponent } from 'src/app/shared/components/pop-up/pop-up.component';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { SwiperComponent } from 'ngx-useful-swiper';

@Component({
  selector: 'app-public-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  providers: [NgbCarouselConfig],
})
export class PublicHomeComponent implements OnInit {
  @ViewChild(SwiperComponent) swiper: SwiperComponent;
  loading = false;
  cpf: string;
  cpfTwo: string;
  link: string;
  icon2via: any;

  config: SwiperOptions = {
    slidesPerView: 1.0,

    navigation: {
      nextEl: '',
      prevEl: '',
    },
    spaceBetween: 30,
    breakpoints: {
      640: {
        slidesPerView: 1.0,
        spaceBetween: 30,
      },
      768: {
        slidesPerView: 2.0,
        spaceBetween: 30,
      },
      1024: {
        slidesPerView: 2.0,
        spaceBetween: 30,
      },
      1200: {
        slidesPerView: 3.0,
        spaceBetween: 30,
      },
      1300: {
        slidesPerView: 2.0,
        spaceBetween: 30,
      },
    },
  };

  configCompanies: SwiperOptions = {
    autoplay: {
      disableOnInteraction: false,
      delay: 3000,
    },
    slidesPerView: 4,
    loop: true,
    breakpoints: {
      200: {
        slidesPerView: 2,
        spaceBetween: 30,
        pagination: { el: '.swiper-pagination', clickable: true },
      },
      // when window width is >= 480px
      1200: {
        slidesPerView: 4,
      },
    },
  };

  constructor(
    private router: Router,
    private modalService: NgbModal,
    private gtmService: GoogleTagManagerService
  ) { }

  ngOnInit(): void {
    document.all[0].setAttribute(
      'data-evt-page',
      '6f96db58-2bf7-4644-ab35-2e941c2635eb'
    );
    (this.icon2via = '../../../assets/images/icon_2via.svg'),
      MenuInitService.init('home', false);
  }

  openPopUp() {
    let modalRef = this.modalService.open(PopUpComponent, {
      centered: true,
      backdrop: false,
    });
  }

  onClick(step) {
    switch (step) {
      case '1':
        const gtmTag1 = {
          event: 'eventGA',
          category: 'portal:home:passo-a-passo',
          action: 'clicou',
          label: 'botao:consultar-cpf',
        };
        this.gtmService.pushTag(gtmTag1);
        break;

      case '2':
        const gtmTag2 = {
          event: 'eventGA',
          category: 'portal:home:passo-a-passo',
          action: 'clicou',
          label: 'botao:cadastrar',
        };
        this.gtmService.pushTag(gtmTag2);
        break;

      case '3':
        const gtmTag3 = {
          event: 'eventGA',
          category: 'portal:home:passo-a-passo',
          action: 'clicou',
          label: 'botao:negociar-divida',
        };
        this.gtmService.pushTag(gtmTag3);
        break;

      case '4':
        const gtmTag4 = {
          event: 'eventGA',
          category: 'portal:home:passo-a-passo',
          action: 'clicou',
          label: 'botao:fazer-proposta',
        };
        this.gtmService.pushTag(gtmTag4);
        break;

      case '5':
        const gtmTag5 = {
          event: 'eventGA',
          category: 'portal:home:passo-a-passo',
          action: 'clicou',
          label: 'botao:pagar-fatura',
        };
        this.gtmService.pushTag(gtmTag5);
        break;
    }

    if (step == '1') {
      window.scrollTo(0, 3600);
    } else {
      this.router.navigate(['cadastrar']);
    }
    if (step != '1') {
      const returnIpanema = JSON.parse(sessionStorage.getItem('returnIpanema'));
      if (returnIpanema) {
        this.router.navigate(['painel']);
      } else {
        this.router.navigate(['cadastrar']);
      }
    }
  }

  public cpfcnpjFooterButtonGTM() {
    const gtmTag = {
      event: 'eventGA',
      category: 'portal:home:consultar-cpf-footer',
      action: 'preencheu',
      label: 'input:cpf',
    };
    this.gtmService.pushTag(gtmTag);
  }

  public dividasButtonGTM() {
    const gtmTag = {
      event: 'eventGA',
      category: 'portal:home:canais',
      action: 'clicou',
      label: 'link:buscar-dividas',
    };
    this.gtmService.pushTag(gtmTag);
    const returnIpanema = JSON.parse(sessionStorage.getItem('returnIpanema'));
    if (returnIpanema) {
      this.router.navigate(['painel']);
    } else {
      this.router.navigate(['cadastrar']);
    }
  }

  public cpfcnpjButtonGTM() {
    const gtmTag = {
      event: 'eventGA',
      category: 'portal:consultar-cpf-topo',
      action: 'preencheu',
      label: 'input:cpf',
    };
    this.gtmService.pushTag(gtmTag);
  }
  swipePrev() {
    this.swiper.swiper.slidePrev();
  }
  swipeNext() {
    this.swiper.swiper.slideNext();
  }
}
