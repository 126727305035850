<div class="col-sm-12 col-md-12 col-lg-12 form-element-parent">
  <div class="form-element">
    <label for="first-cpf" class="label-cpf no-spaces">Consulte aqui seu CPF/CNPJ</label>
    <input id="first-cpf" type="text" class="input-cpf" placeholder="Digite aqui"
      [(ngModel)]="cpf" [ngModelOptions]="{ standalone: true }" [mask]="mask" (input)="validate()"
      (keyup)="maskInput($event)" maxlength="18" data-evt-collect="true"
      data-evt-category="f3682a46-8e88-486a-ae4a-1b3ef41f4d38" data-evt-type="4f117429-4a95-4bae-b603-8dd3dc74ccee"
      data-evt-context-id="first-cpf" data-evt-element-id="CPF Input" data-evt-context-dynamic="true" />
    <button [id]="idGtag" type="button" class="button primary home-cpf-button" [disabled]="!cpf || disabled"
      (click)="getByCpf(cpf)" data-evt-collect="true" data-evt-category="f3682a46-8e88-486a-ae4a-1b3ef41f4d38"
      data-evt-type="18bde62f-bc03-4c32-96fe-bf58df7b81c4" data-evt-context-id="buttton-consultar"
      data-evt-element-id="Consultar">
      Consultar
    </button>
  </div>
</div>
