import { Component, Output, EventEmitter, Input } from '@angular/core';

@Component({
  templateUrl: 'toggle-button.component.html',
  selector: 'app-toggle-button',
  styleUrls: ['toggle.component.scss'],
})
export class ToggleButtonComponent {
  @Output() clickAction = new EventEmitter();

  @Output() blurAction = new EventEmitter();

  toggled = false;

  @Input() isPrimary = false;

  clickEvent(event) {
    event.stopPropagation();
    event.preventDefault();
    this.toggled = !this.toggled;
    this.clickAction.emit(this.toggled);
  }

  blurEvent() {
    this.blurAction.emit();
    this.toggled = false;
  }
}
