import { MenuModel } from './menu.model';

export class MenuConfig {
  public static items: MenuModel[] = [
    {
      identifier: 'home',
      route: '/',
      text: 'MENU.HOME',
      fibbia_evt_collector: 'true',
      fibbia_evt_category: 'd1273286-0cb8-46c9-9ae6-b7c35c680975',
      fibbia_evt_type: '5e48115d-6ada-4ff5-a140-09b9e963b0b1',
      fibbia_evt_context_id: 'home',
      fibbia_evt_element_id: 'Logo / Início',
    },
    {
      identifier: 'court-debts',
      route: 'precatorios',
      text: 'MENU.COURT-ORDER-DEBTS',
      fibbia_evt_collector: 'true',
      fibbia_evt_category: 'd1273286-0cb8-46c9-9ae6-b7c35c680975',
      fibbia_evt_type: '5e48115d-6ada-4ff5-a140-09b9e963b0b1',
      fibbia_evt_context_id: 'court-debt',
      fibbia_evt_element_id: 'Precatório',
    },
    {
      identifier: 'about',
      route: 'sobre-ipanema',
      text: 'MENU.ABOUT',
      fibbia_evt_collector: 'true',
      fibbia_evt_category: 'd1273286-0cb8-46c9-9ae6-b7c35c680975',
      fibbia_evt_type: '5e48115d-6ada-4ff5-a140-09b9e963b0b1',
      fibbia_evt_context_id: 'about',
      fibbia_evt_element_id: 'Sobre nós',
    },
    {
      identifier: 'dropdown',
      route: '#',
      text: 'MENU.DROPDOWN.TITLE',
      dropdown: true,
      items: [
        {
          route: '/painel/dividas',
          text: 'MENU.DROPDOWN.ITEMS.FIRST',
          fibbia_evt_collector: 'true',
          fibbia_evt_category: 'f3682a46-8e88-486a-ae4a-1b3ef41f4d38',
          fibbia_evt_type: '5e48115d-6ada-4ff5-a140-09b9e963b0b1',
          fibbia_evt_context_id: 'dividas',
          fibbia_evt_element_id: 'Negocie sua dívida',
        },
        {
          route: '/painel/acordos',
          text: 'MENU.DROPDOWN.ITEMS.SECOND',
          fibbia_evt_collector: 'true',
          fibbia_evt_category: 'f3682a46-8e88-486a-ae4a-1b3ef41f4d38',
          fibbia_evt_type: '5e48115d-6ada-4ff5-a140-09b9e963b0b1',
          fibbia_evt_context_id: 'segunda_via',
          fibbia_evt_element_id: '2a via de fatura',
        },
        {
          route: '/painel/acordos',
          text: 'MENU.DROPDOWN.ITEMS.THIRD',
          fibbia_evt_collector: 'true',
          fibbia_evt_category: 'f3682a46-8e88-486a-ae4a-1b3ef41f4d38',
          fibbia_evt_type: '5e48115d-6ada-4ff5-a140-09b9e963b0b1',
          fibbia_evt_context_id: 'acordos',
          fibbia_evt_element_id: 'Ver acordos',
        },
      ],
    },
    {
      identifier: 'contact',
      route: 'contato',
      text: 'MENU.FAQ',
      fibbia_evt_collector: 'true',
      fibbia_evt_category: 'd1273286-0cb8-46c9-9ae6-b7c35c680975',
      fibbia_evt_type: '5e48115d-6ada-4ff5-a140-09b9e963b0b1',
      fibbia_evt_context_id: 'contato',
      fibbia_evt_element_id: 'Contato',
    },
  ];
}
