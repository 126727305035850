import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AbstractService {

  model;

  constructor(
    protected http: HttpClient
  ) {}

  get(params?) {
    return this.http.get<any[]>(`${environment.apiUrl}/${this.model}`, {params: params});
  }

  getResponse(params?) {
    return this.http.get<any[]>(`${environment.apiUrl}/${this.model}`, {params: params, observe: 'response'});
  }

  getById(id) {
    return this.http.get<any>(`${environment.apiUrl}/${this.model}/` + id);
  }

  doPost(data) {
    return this.http.post(`${environment.apiUrl}/${this.model}`, data);
  }
}
