import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { NgbCarousel, NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { SwiperComponent } from 'ngx-useful-swiper';
import { SwiperOptions } from 'swiper';
@Component({
  selector: 'app-banner-home',
  templateUrl: './banner-home.component.html',
  styleUrls: ['./banner-home.component.scss'],
})
export class BannerHomeComponent implements OnInit {
  configCompanies: SwiperOptions = {
    autoplay: {
      disableOnInteraction: false,
      delay: 7000,
    },
    slidesPerView: 1,
    loop: true,
    watchSlidesProgress: true,
    watchSlidesVisibility: true,
    pagination: { el: '.swiper-pagination', clickable: true },
    scrollbar: {
      draggable: true,
    },
  };

  constructor(
    private router: Router,
    private gtmService: GoogleTagManagerService,
    private ngConfig: NgbCarouselConfig
  ) {
    ngConfig.showNavigationArrows = false;
    ngConfig.interval = 6000;
  }

  ngOnInit(): void { }

  public redirectSignUp() {
    const gtmTag = {
      event: 'eventGA',
      category: 'portal:banner-principal',
      action: 'clicou',
      label: 'botao:negociar',
    };
    this.gtmService.pushTag(gtmTag);

    const returnIpanema = JSON.parse(sessionStorage.getItem('returnIpanema'));
    if (returnIpanema) {
      this.router.navigate(['painel']);
    } else {
      this.router.navigate(['cadastrar']);
    }
  }

  segundaViaBoleto() {
    this.router.navigate(['/painel/acordos']);

    const gtmTag = {
      event: 'eventGA',
      category: 'banner:2a-via',
      action: 'clicou',
      label: 'banner:2a-via',
    };
    this.gtmService.pushTag(gtmTag);
  }

  public cpfcnpjButtonGTM() {
    const gtmTag = {
      event: 'eventGA',
      category: 'portal:consultar-cpf-topo',
      action: 'preencheu',
      label: 'input:cpf',
    };
    this.gtmService.pushTag(gtmTag);
  }
}
