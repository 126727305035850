import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  templateUrl: './channel-card.component.html',
  selector: 'app-channel-card',
  styleUrls: ['./channel-card.component.scss'],
})
export class ChannelCardComponent {
  @Input() channelTitle = '';

  @Input() channelSubtitle = '';

  @Input() srcIcon = '';

  @Input() action = '';

  @Input() srcIconWhite = '';

  @Input() target = '';

  @Input() targetType = '';

  @Input() idGtag = '';

  @Input() fibbia_evt_collector = '';

  @Input() fibbia_evt_category = '';

  @Input() fibbia_evt_type = '';

  @Input() fibbia_evt_context_id = '';

  @Input() fibbia_evt_element_id = '';

  isHover = false;

  constructor(private router: Router) { }

  hover() {
    this.isHover = !this.isHover;
  }

  clickInnerElements(event) {
    event.preventDefault();
    event.stopPropagation();
    var clickEvent = event.target;
    var clickSelector = clickEvent?.closest('.card-wrapper');
    if (!clickSelector) return false;
    clickSelector.click();
  }

  goTo(target) {
    if (this.targetType && this.targetType == 'intern') {
      this.router.navigate([target]);
    } else if (this.targetType && this.targetType == 'other') {
      window.open(target, '_self');
    } else {
      window.open(target);
    }
  }
}
