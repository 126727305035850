<div class="hide-if-mobile-menu no-spaces">
  <header class="public-menu" [ngClass]="{ active: setClassHeader }">
    <div class="menu-logo">
      <img data-evt-collect="true" data-evt-category="d1273286-0cb8-46c9-9ae6-b7c35c680975"
        data-evt-type="5e48115d-6ada-4ff5-a140-09b9e963b0b1" data-evt-context-id="Logo / Início"
        data-evt-element-id="Logo / Início" src="../assets/images/logo.svg" alt="Ipanema" (click)="goHome()"
        (keypress)="goHome()" loading="lazy" />


    </div>
    <div class="menu-items">
      <ul class="navbar nav justify-content-end">
        <app-menu-item *ngFor="let item of items" [text]="item.text" [menuConfig]="menuConfig"
          [isButton]="item.isButton" [dropdown]="item.dropdown" [items]="item.items" [buttonStyle]="item.buttonStyle"
          [isPrimary]="setClassHeader" [identifier]="item.identifier" [route]="item.route" [idGtag]="item.idGtag"
          [fibbia_evt_collector]="item.fibbia_evt_collector" [fibbia_evt_category]="item.fibbia_evt_category"
          [fibbia_evt_type]="item.fibbia_evt_type" [fibbia_evt_context_id]="item.fibbia_evt_context_id"
          [fibbia_evt_element_id]="item.fibbia_evt_element_id" (click)="itemsButtonGTM(item)"></app-menu-item>

        <app-menu-item *ngFor="let item of buttonsItems" [text]="item.text" [menuConfig]="menuConfig"
          [isButton]="item.isButton" [dropdown]="item.dropdown" [items]="item.items" [buttonStyle]="item.buttonStyle"
          [isPrimary]="setClassHeader" [identifier]="item.identifier" [route]="item.route"
          [fibbia_evt_collector]="item.fibbia_evt_collector" [fibbia_evt_category]="item.fibbia_evt_category"
          [fibbia_evt_type]="item.fibbia_evt_type" [fibbia_evt_context_id]="item.fibbia_evt_context_id"
          [fibbia_evt_element_id]="item.fibbia_evt_element_id" (click)="registerLoginButtonGTM(item)">
        </app-menu-item>
      </ul>
    </div>
  </header>
</div>

<div class="hide-if-desktop-menu">
  <header class="public-menu" [ngClass]="{
      primary: menuConfig.isPrimary,
      secondary: !menuConfig.isPrimary
    }">
    <div class="menu-logo">
      <img data-evt-collect="true" data-evt-category="d1273286-0cb8-46c9-9ae6-b7c35c680975"
        data-evt-type="5e48115d-6ada-4ff5-a140-09b9e963b0b1" data-evt-context-id="Logo / Início"
        data-evt-element-id="Logo / Início" src="../assets/images/logo.svg" alt="Ipanema" (click)="goHome()"
        (keypress)="goHome()" rel="preload" />
    </div>
    <div class="menu-items">
      <ul class="nav justify-content-end">
        <li class="nav-item">
          <app-toggle-button (clickAction)="click($event)" #toggleButton (blurAction)="blurClick()"></app-toggle-button>
        </li>
      </ul>
    </div>
  </header>
</div>
<div class="mobile-menu hide-if-desktop-menu" *ngIf="toggled">
  <ul>
    <app-menu-item *ngFor="let item of items" [text]="item.text" [menuConfig]="menuConfig" [isButton]="item.isButton"
      [dropdown]="item.dropdown" [items]="item.items" [buttonStyle]="item.buttonStyle" [isPrimary]="setClassHeader"
      [identifier]="item.identifier" [route]="item.route" [fibbia_evt_collector]="item.fibbia_evt_collector"
      [fibbia_evt_category]="item.fibbia_evt_category" [fibbia_evt_type]="item.fibbia_evt_type"
      [fibbia_evt_context_id]="item.fibbia_evt_context_id" [fibbia_evt_element_id]="item.fibbia_evt_element_id"
      (toggled)="click($event)"></app-menu-item>
    <app-menu-item *ngFor="let item of buttonsItems" [text]="item.text" [menuConfig]="menuConfig"
      [isButton]="item.isButton" [dropdown]="item.dropdown" [items]="item.items" [buttonStyle]="item.buttonStyle"
      [isPrimary]="setClassHeader" [identifier]="item.identifier" [route]="item.route"
      [fibbia_evt_collector]="item.fibbia_evt_collector" [fibbia_evt_category]="item.fibbia_evt_category"
      [fibbia_evt_type]="item.fibbia_evt_type" [fibbia_evt_context_id]="item.fibbia_evt_context_id"
      [fibbia_evt_element_id]="item.fibbia_evt_element_id" (click)="menuButtonGTM()"></app-menu-item>
  </ul>
</div>

<a *ngIf="router.url != '/precatorios'" rel="noopener noreferrer" id="side_whatsapp"
  href="https://api.whatsapp.com/send?phone=5508000250000" target="_blank" class="contact-link"
  (click)="whatsappButtonGTM()">
  <img src="assets/images/whatsapp-fixed.svg" class="whatsapp-fixed hide-if-mobile-menu" alt="whatsapp" rel="preload" />
</a>
<a *ngIf="router.url == '/precatorios'" rel="noopener noreferrer" id="side_whatsapp"
  href="https://api.whatsapp.com/send?phone=551151960848" target="_blank" class="contact-link"
  (click)="whatsappButtonGTM()">
  <img src="assets/images/whatsapp-fixed.svg" class="whatsapp-fixed hide-if-mobile-menu" alt="whatsapp" rel="preload" />
</a>

<a href="tel:08000180048" *ngIf="router.url == '/precatorios'" class="phone-call hide-if-desktop-menu"><img
    src="assets/images/fi_phone.svg" width="50" alt="Call Now" title="Call Now"></a>

<!-- <div class="fab hide-if-desktop-menu" *ngIf="router.url != '/entrar'" >
  <button class="main" (click)="openFab($event)" (blur)="blurMenu($event)">
  </button>

  <ul *ngIf="openMenu == true">
    <li>
      <button id="opcao1" class="phone" *ngIf="router.url != '/precatorios'" onclick="window.location.href='tel:08000250000'">
      </button>
      <button id="opcao1" class="phone" *ngIf="router.url == '/precatorios'" onclick="window.location.href='tel:08000180048'">
      </button>

    </li>
    <li>
        <button id="opcao2" class="whatsapp" rel="noopener noreferrer" *ngIf="router.url == '/precatorios'" onclick="window.open('https://api.whatsapp.com/send?phone=551151960848', '_blank')"
        (click)="whatsappButtonGTM()" ></button>

        <button id="opcao2" class="whatsapp"  rel="noopener noreferrer" *ngIf="router.url != '/precatorios'" onclick="window.open('https://api.whatsapp.com/send?phone=5508000250000', '_blank')"
        (click)="whatsappButtonGTM()">
        </button>
    </li>
  </ul>
</div> -->


<div class="phone-call-whatsapp hide-if-desktop-menu" tabindex="0" (click)="openWhatsappCommon($event)"
  *ngIf="router.url != '/entrar' && router.url != '/entrar/redefinir-senha' && router.url != '/cadastrar' && router.url != '/precatorios'">
  <img src="assets/images/fi_whatsapp_white.svg">
</div>
<div class="phone-call-whatsapp hide-if-desktop-menu" tabindex="0" (click)="openWhatsappCourtDebts($event)"
  *ngIf="router.url != '/entrar' && router.url != '/entrar/redefinir-senha' && router.url != '/cadastrar' && router.url == '/precatorios'">
  <img src="assets/images/fi_whatsapp_white.svg">
</div>
<div class="phone-call hide-if-desktop-menu" tabindex="0" (click)="openCallCommon($event)"
  *ngIf="router.url != '/entrar' && router.url != '/entrar/redefinir-senha' && router.url != '/cadastrar' && router.url != '/precatorios'">
  <img src=" assets/images/fi_phone_white.svg">
</div>
<div class="phone-call hide-if-desktop-menu" tabindex="0" (click)="openCallCourtDebts($event)"
  *ngIf="router.url != '/entrar' && router.url != '/entrar/redefinir-senha' && router.url != '/cadastrar' && router.url == '/precatorios'">
  <img src=" assets/images/fi_phone_white.svg">
</div>



<!-- <a rel="noopener noreferrer" id="side_messenger" href="https://sac-ipanemacm.bstecnologia.com.br/Chat/" target="_blank" class="contact-link" (click)="messengerButtonGTM()">
  <img src="assets/images/messenger-fixed.svg" class="messenger-fixed hide-if-mobile" alt="messenger" rel="preload"/>
</a> -->