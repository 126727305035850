import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import {
  NgbActiveModal,
  NgbModule,
  NgbTooltipModule,
} from '@ng-bootstrap/ng-bootstrap';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { NgBootstrapFormValidationModule } from 'ng-bootstrap-form-validation';
import { NgxMaskModule } from 'ngx-mask';
import { NgxUsefulSwiperModule } from 'ngx-useful-swiper';
import { NgxYoutubePlayerModule } from 'ngx-youtube-player';

import { AuthContentComponent } from './components/auth-content/auth-content.component';
import { BannerLgpdComponent } from './components/banner-lgpd/banner-lgpd.component';
import { BoxInputComponent } from './components/box-input/box-input.component';
import { ChannelCardComponent } from './components/channel-card/channel-card.component';
import { ChannelsSectionComponent } from './components/channels-section/channels-section.component';
import { CpfCnpjInputComponent } from './components/cpf-cnpj-input/cpf-cnpj-input.component';
import { DebtAgreementComponent } from './components/debt-agreement/debt-agreement.component';
import { DebtCardComponent } from './components/debt-card/debt-card.component';
import { DebtProposalComponent } from './components/debt-proposal/debt-proposal.component';
import { LoadingComponent } from './components/loading/loading.component';
import { OnboardingComponent } from './components/onboarding/onboarding.component';
import { OpinionSectionComponent } from './components/opinion-section/opinion-section.component';
import { PrivacyCenterComponent } from './components/privacy-center/privacy-center.component';
import { CorporationSwiperComponent } from './components/public/corporation-swiper/corporation-swiper.component';
import { TermsAndServicesComponent } from './components/terms-and-services/terms-and-services.component';
import { AcceptProposalComponent } from './components/accept-proposal/accept-proposal.component';
import { ProfileModalComponent } from './components/profile-modal/profile-modal.component';
import { MakeYourProposalComponent } from './components/make-your-proposal/make-your-proposal.component';
import { ConfirmAcceptProposalComponent } from './components/confirm-accept-proposal/confirm-accept-proposal.component';
import { DischargeModalComponent } from './components/discharge-modal/discharge-modal.component';
import { EmptyStateComponent } from './components/empty-state/empty-state.component';
import { StatusDebtComponent } from './components/status-debt/status-debt.component';
import { ConfirmCustomProposalComponent } from './components/confirm-custom-proposal/confirm-custom-proposal.component';
import { ArrangementInDebtComponent } from './components/arrangement-in-debt/arrangement-in-debt.component';
import { UserMigrationModalComponent } from './components/user-migration-modal/user-migration-modal.component';
import { PopUpComponent } from './components/pop-up/pop-up.component';
import { PopUpOutputComponent } from './components/pop-up-output/pop-up-output.component';
import { ConfirmUserAccountComponent } from './components/confirm-user-account/confirm-user-account.component';

import { FactoryOrValue } from 'rxjs';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { BannerHomeComponent } from './components/banner-home/banner-home.component';
import { PopUpPixComponent } from './components/pop-up-pix/pop-up-pix.component';
import { StatusInstallmentComponent } from './components/status-installment/status-installment.component';
import { CustomTranslateLoader } from 'src/assets/i18n/translateLoader';
import { ConfirmPendingProposalComponent } from './components/confirm-pending-proposal/confirm-pending-proposal.component';
import { CpfCnpjPipe } from './utils/pipes/cpfCnpj.pipe';
import { SegmentCarouselComponent } from './components/segment-carousel/segment-carousel.component';
import { TimerComponent } from './components/timer/timer.component';
import { ConfirmPaymentModalComponent } from './components/confirm-payment-modal/confirm-payment-modal.component';
import { CpfCnpjInputMobileComponent } from './components/cpf-cnpj-input-mobile/cpf-cnpj-input-mobile.component';
import { AuthContentSantanderComponent } from './components/auth-content-santander/auth-content-santander.component';
import { CookiesPolicyComponent } from './components/cookies-policy/cookies-policy.component';

export function HttpLoaderFactory(http: HttpClient): FactoryOrValue<any> {
  //To avoid cache
  let random = Math.floor(Math.random() * 1000000000000000);
  return new TranslateHttpLoader(
    http,
    './assets/i18n/',
    `.json?nocache=${random}`
  );
}
// Components
@NgModule({
  declarations: [
    // Components
    BannerLgpdComponent,
    TermsAndServicesComponent,
    AuthContentComponent,
    AuthContentSantanderComponent,
    BoxInputComponent,
    LoadingComponent,
    CorporationSwiperComponent,
    PrivacyCenterComponent,
    OnboardingComponent,
    DebtAgreementComponent,
    DebtProposalComponent,
    CpfCnpjInputComponent,
    CpfCnpjInputMobileComponent,
    ChannelsSectionComponent,
    ChannelCardComponent,
    OpinionSectionComponent,
    DebtCardComponent,
    ProfileModalComponent,
    AcceptProposalComponent,
    MakeYourProposalComponent,
    ConfirmAcceptProposalComponent,
    ConfirmPendingProposalComponent,
    DischargeModalComponent,
    EmptyStateComponent,
    StatusDebtComponent,
    StatusInstallmentComponent,
    ConfirmCustomProposalComponent,
    ArrangementInDebtComponent,
    UserMigrationModalComponent,
    PopUpComponent,
    PopUpOutputComponent,
    ConfirmUserAccountComponent,
    BannerHomeComponent,
    BannerHomeComponent,
    PopUpPixComponent,
    CpfCnpjPipe,
    SegmentCarouselComponent,
    TimerComponent,
    ConfirmPaymentModalComponent,
    CookiesPolicyComponent,
  ],
  imports: [
    RouterModule,
    CommonModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    NgBootstrapFormValidationModule.forRoot(),
    NgxMaskModule.forRoot(),
    NgxYoutubePlayerModule.forRoot(),
    TranslateModule.forRoot({
      defaultLanguage: 'pt-br',
      loader: {
        provide: TranslateLoader,
        useClass: CustomTranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    NgxUsefulSwiperModule,
  ],
  providers: [NgbActiveModal, NgbTooltipModule],
  exports: [
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    NgBootstrapFormValidationModule,
    NgxMaskModule,
    NgxYoutubePlayerModule,
    AuthContentComponent,
    AuthContentSantanderComponent,
    BoxInputComponent,
    TermsAndServicesComponent,
    PrivacyCenterComponent,
    BannerLgpdComponent,
    LoadingComponent,
    CorporationSwiperComponent,
    DebtAgreementComponent,
    DebtProposalComponent,
    CpfCnpjInputComponent,
    CpfCnpjInputMobileComponent,
    ChannelsSectionComponent,
    ChannelCardComponent,
    OpinionSectionComponent,
    DebtCardComponent,
    DischargeModalComponent,
    MakeYourProposalComponent,
    EmptyStateComponent,
    StatusDebtComponent,
    StatusInstallmentComponent,
    ArrangementInDebtComponent,
    UserMigrationModalComponent,
    PopUpComponent,
    PopUpOutputComponent,
    ConfirmUserAccountComponent,
    BannerHomeComponent,
    PopUpPixComponent,
    CpfCnpjPipe,
    SegmentCarouselComponent,
  ],
})
export class SharedModule { }
